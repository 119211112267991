import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export const processPlansetPdf =createAsyncThunk(
  'planSetAnalyzer/processPlansetPdf',
  async ({ file, userId }, { rejectWithValue }) => {
    try {
      const formData = new FormData();
      formData.append('pdfFile', file);
      formData.append('userId', userId);

      const response = await axios.post(
        'https://planset.framesense.ai/api/analyze',
        formData,
        {
          headers: {
            Authorization: 'Wattmonk321',
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || 'An error occurred');
    }
  }
);
  

export const processPlansetChat = createAsyncThunk(
  'planSetAnalyzer/chatQuery',
  async ({ query, jobId }, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        'https://planset.framesense.ai/api/chat',
        {
          query,
          jobId,
        },
        {
          headers: {
            Authorization: 'Wattmonk321',
            'Content-Type': 'application/json',
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || 'An error occurred');
    }
  }
);

const planSetAnalyzerSlice = createSlice({
    name: 'plansetchatandpdf',
    initialState: {
        selectedPlanSetFile: null,
        plansetPdfresponseData: null,
        loading: false,
        error: null,
        plansetchatResponseData: null,
        chatloading: false,
        chaterror: null,
    },
    reducers: {
        setSelectedPlansetFile: (state, action) => {
            state.selectedPlanSetFile = action?.payload;
            state.plansetPdfresponseData = null; // Reset previous response
            state.error = null; // Reset previous errors
          },
          resetPlansetPdfData: (state) => {
            state.selectedPlanSetFile = null;
            state.plansetPdfresponseData = null;
            state.loading = false;
            state.error = null;
          },
        resetPlansetChat: (state) => {
            state.plansetchatResponseData = null;
            state.chatloading = false;
            state.chaterror = null;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(processPlansetPdf.pending, (state) => {
            state.loading = true;
          })
          .addCase(processPlansetPdf.fulfilled, (state, action) => {
            state.loading = false;
            state.plansetPdfresponseData = action?.payload;
          })
          .addCase(processPlansetPdf.rejected, (state, action) => {
            state.loading = false;
            state.error = action?.payload;
          }).addCase(processPlansetChat.pending, (state) => {
                state.chatloading = true;
            }).addCase(processPlansetChat.fulfilled, (state, action) => {
                state.chatloading = false;
                state.plansetchatResponseData = action?.payload;
            }).addCase(processPlansetChat.rejected, (state, action) => {
                state.chatloading = false;
                state.chaterror = action?.payload;
            });
    },
});

export const { setSelectedPlansetFile,resetPlansetPdfData,resetPlansetChat } = planSetAnalyzerSlice.actions;

export default planSetAnalyzerSlice.reducer;
