import React, { useEffect, useState } from 'react';
import { Box, Typography , Button } from '@mui/material';
import { useWebSocket } from '../contexts/WebSocketContext';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { processPdf, setSelectedFile, reset } from '../redux/survey/pdfSlice';
import SkeletonLoader from '../components/SkeletonLoader';
import Upload from '@mui/icons-material/Upload';
import AddIcon from '@mui/icons-material/Add';
import { useTheme } from '@mui/material/styles';

import { fetchsearchesTabs } from '../redux/survey/uploadHistorySlice';
const SurveyAnalyzer = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';
  const { selectedFile, responseData, loading, error } = useSelector((state) => state.pdf || {});
  const { searchstabsResponse } = useSelector((state) => state.uploadHistory);

  const { connectWebSocket, messages } = useWebSocket();
  const [pdfFile, setPdfFile] = useState(null);
  const [askQuestionsOpen, setAskQuestionsOpen] = useState(false);
  const [isChatScreenOpen, setIsChatScreenOpen] = useState(false);
  const [expanded, setExpanded] = useState(true);

  const handleExpandToggle = () => {
    setExpanded((prev) => !prev);
  };

  // Navigate to a new chat screen
  const handleNewChat = () => {
    navigate('/survey-analyzer');
  }

  // Toggle chat visibility
  const handleToggleChat = () => {
    setIsChatScreenOpen(!isChatScreenOpen);
  };

  

  // Handle file upload and check for PDF files only
  const handleFileChange = (event) => {
    const storedUser = JSON.parse(localStorage.getItem('user'));
    const files = Array.from(event.target.files);
  
    if (files.length > 0) {
      const areAllPdfs = files.every((file) => file.type === 'application/pdf');
      if (areAllPdfs) {
        dispatch(setSelectedFile(files[0]));
        const userId = storedUser?._id;
        dispatch(processPdf({ files, userId ,agent_name:'survey'}));
      } else {
        alert('Please select only PDF files.');
        event.target.value = '';
      }
    }
  };

  useEffect(() => {
    console.log("Hello this is response ",responseData);

    const storedUser = JSON.parse(localStorage.getItem('user'));
    if (responseData && responseData?.request_id !== undefined ) {
      connectWebSocket(responseData?.request_id, responseData?.websocket_url);
      navigate(`/threads/${responseData?.request_id}`, { state: { url: responseData.websocket_url, request_id: responseData?.request_id } });
      dispatch(fetchsearchesTabs(storedUser?._id)); 
    }
  }, [responseData, navigate]);


  // Reset state on component unmount
  useEffect(() => {
    return () => {
      dispatch(reset());
    };
  }, [dispatch]);

  return (
    <Box sx={{ position: 'relative', textAlign: 'center' }}>
      

      {/* Survey Analyzer Text */}
      <div>
      <Typography
        variant="h6"
        sx={{
          position: 'absolute',
          top: "-150px",
          left: "2px", // Adjust this value to position text correctly
          fontWeight: 'bold',
          color: (theme) => theme.palette.mode === 'dark' ? '#FFFFFF' : '#1F2937',
        }}
      >
        Survey Analyzer
      </Typography>

      </div>


      {/* Display when there's no file selected */}
      {!loading && !responseData && (
        <Box
          sx={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: "100px",
          }}
        >
          <Box
            sx={{
              textAlign: 'center',
              marginBottom: 4,
            }}
          >
            <Box
              sx={{
                width: 96,
                height: 96,
                backgroundColor: isDarkMode ? '#616161' : '#E0E0E0',
                borderRadius: '50%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginBottom: 2,
                marginX: 'auto',
              }}
            >
              <Upload sx={{ fontSize: '48px', color: isDarkMode ? '#B0BEC5' : '#9E9E9E' }} />
            </Box>
            <Typography variant="h5" sx={{ fontWeight: 'bold', marginBottom: 1, color: isDarkMode ? '#FFFFFF' : '#1F2937' }}>
              {selectedFile ? 'Ready to analyze' : 'No content to display'}
            </Typography>
            <Typography variant="body2" sx={{ color: isDarkMode ? '#B0BEC5' : '#6B7280', marginBottom: 2 }}>
              Upload a file to start analyzing your survey data
            </Typography>
            {selectedFile && (
              <Typography variant="body2" sx={{ color: isDarkMode ? '#B0BEC5' : '#6B7280', marginBottom: 2 }}>
                Selected file: {selectedFile.name}
              </Typography>
            )}
            {error && (
              <Typography variant="body2" color="error" sx={{ marginBottom: 2 }}>
                {error}
              </Typography>
            )}
            <Box sx={{ mt: 3 }}>
              <input
                type="file"
                accept="application/pdf"
                multiple
                onChange={handleFileChange}
                style={{ display: 'none' }}
                id="file-upload"
              />
              <label htmlFor="file-upload">
                <Button
                  variant="contained"
                  component="span"
                  sx={{
                    padding: "8px",
                    borderRadius: '5px',
                    backgroundColor: isDarkMode ? '#1976D2' : '#3B82F6',
                    color: '#FFFFFF',
                    '&:hover': {
                      backgroundColor: isDarkMode ? '#2196F3' : '#2563EB',
                    },
                  }}
                >
                  Choose File
                </Button>
              </label>
            </Box>
          </Box>
        </Box>
      )}

      {/* Loading and display components */}
      {loading && <SkeletonLoader />}
      {responseData && askQuestionsOpen && (
        <Button
          sx={{
            position: 'fixed',
            top: "250px !important",
            right: "0px !important",
            zIndex: 1000,
            backgroundColor: '#3f51b5',
            color: '#fff',
            '&:hover': {
              backgroundColor: '#303f9f',
            },
          }}
          onClick={handleToggleChat}
        >
          {isChatScreenOpen ? 'Close Chat' : 'Open Chat'}
        </Button>
      )}
    </Box>
  );
};

export default SurveyAnalyzer;
