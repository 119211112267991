import React, { useEffect, useState } from 'react';
import { Box, Typography, Button } from '@mui/material';
import { fetchUploadHistory, resetUploadHistory } from '../redux/survey/uploadHistorySlice';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import SurveyHistoryDataDisplay from '../components/SurveyHistoryDataDisplay';
import { useWebSocket } from '../contexts/WebSocketContext';
import SurveyDataDisplay from '../components/SurveyDataDisplay';
import SkeletonLoader from '../components/SkeletonLoader';

const ThreadsPage = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { checkConnectionStatus } = useWebSocket();
  const location = useLocation();
  const websocketUrl = location.state?.url;
  const request_id = location.state?.request_id;

  const { uploadHistory, loading, error } = useSelector((state) => state.uploadHistory || {});
  const [isConnected, setIsConnected] = useState(false);
  const [askQuestionsOpen, setAskQuestionsOpen] = useState(false);
  const [isChatScreenOpen, setIsChatScreenOpen] = useState(false);



  useEffect(() => {
    // Check if there's no websocket URL in state before fetching upload history

    if (id && !websocketUrl) {
      const connectionStatus = checkConnectionStatus(id);
      if (connectionStatus.status === 'pending' || connectionStatus.status === 'connected' ){
        setIsConnected(true);
      } else if(websocketUrl){
        setIsConnected(true)
      }else{
        setIsConnected(false)

        dispatch(fetchUploadHistory(id));
      }
    }else if(websocketUrl && websocketUrl !== undefined){

      setIsConnected(true)
    }
  }, [dispatch, id, websocketUrl]);


  // Reset upload history on unmount
  useEffect(() => {
    return () => {
      dispatch(resetUploadHistory());
    };
  }, [dispatch]);

  // Toggle chat screen visibility
  const handleToggleChat = () => {
    setIsChatScreenOpen((prevState) => !prevState);
  };

  return (
    <Box sx={{ textAlign: 'center' }}>
      {/* Show loader while fetching data */}
      {loading && <SkeletonLoader />}

      {/* Display SurveyHistoryDataDisplay when connected or uploadHistory is available */}
      {!isConnected && uploadHistory && !loading ? (

        <SurveyHistoryDataDisplay
          id={id}
          wsdata={uploadHistory}
          askQuestionsOpen={askQuestionsOpen}
          setAskQuestionsOpen={setAskQuestionsOpen}
          isChatScreenOpen={isChatScreenOpen}
        />
      ) : isConnected  ? (
        <SurveyDataDisplay
          id={id}
          wsdata={{ websocket_url: websocketUrl, request_id: request_id }}
          askQuestionsOpen={askQuestionsOpen}
          setAskQuestionsOpen={setAskQuestionsOpen}
          isChatScreenOpen={isChatScreenOpen}
        />
      ) : (
        !loading && (
          <Typography variant="h6" color="error" sx={{ mt: 4 }}>
            {error ? `Error: ${error.message || 'Unknown error'}` : 'Data Not Found.'}
          </Typography>
        )
      )}

      {/* Button to open/close chat, displayed only when data is available and questions are open */}
      {/* {(uploadHistory || isConnected) && askQuestionsOpen && ( */}
        { askQuestionsOpen && (
        
        <Button
          sx={{
            position: 'fixed',
            top: '250px',
            right: '0px',
            zIndex: 1000,
            backgroundColor: '#3f51b5',
            color: '#fff',
            '&:hover': {
              backgroundColor: '#303f9f',
            },
          }}
          onClick={handleToggleChat}
        >
          {isChatScreenOpen ? 'Close Chat' : 'Open Chat'}
        </Button>
      )} 
    </Box>
  );
};

export default ThreadsPage;
