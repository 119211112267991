import React, { useState, useEffect } from 'react';
import {
  Card, CircularProgress, LinearProgress, Typography, Box,
  TextField, Button, IconButton, useTheme, Grid, CardContent
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import * as XLSX from 'xlsx'; // Import xlsx library
import PlansetChatScreen from "./PlansetChatScreen";

const PlansetDataDisplay = ({ wsdata, loading, setAskQuestionsOpen, isChatScreenOpen }) => {
  const [categorizedData, setCategorizedData] = useState([]);
  const [editingIndex, setEditingIndex] = useState(null); // Track the answer being edited
  const theme = useTheme();

  useEffect(() => {
    if (wsdata) {
      setAskQuestionsOpen(true);
      setCategorizedData(wsdata?.data?.questions || []);
    }
  }, [wsdata, setAskQuestionsOpen]);

  const parseAnswer = (answer) => {
    return answer.startsWith("Error: 401") ? "API Key error: Please check your API key." : answer;
  };

  // Handle answer edit initiation
  const handleEditAnswer = (index) => {
    setEditingIndex(index);
  };

  // Handle answer save
  const handleSaveAnswer = (index, newAnswer) => {
    setCategorizedData((prevData) =>
      prevData.map((item, idx) => idx === index ? { ...item, Answer: newAnswer } : item)
    );
    setEditingIndex(null);
  };

  // Export to Excel handler
  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(categorizedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Questions");
    XLSX.writeFile(workbook, "QuestionsData.xlsx");
  };

  return (
    <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', margin: '0 auto', padding: '0px !important', maxWidth: '1200px', width: '100%', [theme.breakpoints.down('md')]: { flexDirection: 'column' } }}>
      {loading ? (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
          <CircularProgress size={60} sx={{ color: '#3f51b5', marginBottom: '20px' }} />
          <Box sx={{ width: '80%', marginTop: '10px' }}>
            <LinearProgress variant="determinate" sx={{ height: 10, borderRadius: 5, '& .MuiLinearProgress-bar': { backgroundColor: '#3f51b5' } }} />
          </Box>
        </Box>
      ) : (
        <>
          <Card sx={{ width: { xs: '100%', md: isChatScreenOpen ? '55%' : '90%' }, height: 'auto', overflowY: 'auto', mr: { md: 2 }, mb: { xs: 4, md: 0 }, maxHeight: '80vh', position: 'relative', padding: '16px' }}>
            <CardContent>
              {/* Header with center title and top-right button */}
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 4 }}>
                <Typography variant="h5" fontWeight="bold" sx={{ flex: 1, textAlign: 'center' }}>
                  Planset Data
                </Typography>
                <Button variant="contained" color="primary" onClick={exportToExcel} sx={{ position: 'absolute', right: 0, top: 0 }}>
                  Download as Excel
                </Button>
              </Box>
              {categorizedData.map((item, index) => (
                <Box key={index} sx={{ mb: 2, padding: 0, borderBottom: '1px solid #e0e0e0', paddingBottom: 2 }}>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                        <Box sx={{ width: 40, height: 40, borderRadius: '50%', backgroundColor: '#3f51b5', display: 'flex', alignItems: 'center', justifyContent: 'center', color: 'white', fontWeight: 'bold', marginRight: '8px' }}>
                          {`Q${index + 1}`}
                        </Box>
                        <Typography variant="body1" fontWeight="bold" sx={{ textAlign: 'left' }}>
                          {item.question}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'left' }}>
                        {editingIndex === index ? (
                          <TextField
                            fullWidth
                            variant="outlined"
                            value={item.Answer}
                            onChange={(e) =>
                              setCategorizedData((prevData) =>
                                prevData.map((it, idx) =>
                                  idx === index ? { ...it, Answer: e.target.value } : it
                                )
                              )
                            }
                          />
                        ) : (
                          <Typography variant="body2" color="text.secondary" sx={{ flexGrow: 1 }}>
                            Answer: {parseAnswer(item.Answer)}
                          </Typography>
                        )}
                        <IconButton onClick={() => (editingIndex === index ? handleSaveAnswer(index, item.Answer) : handleEditAnswer(index))} sx={{ ml: 1 }}>
                          {editingIndex === index ? <SaveIcon color="primary" /> : <EditIcon color="action" />}
                        </IconButton>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              ))}
            </CardContent>
          </Card>

          {isChatScreenOpen && (
            <Box sx={{ width: { xs: '100%', md: '35%' }, height: 'auto', borderRadius: '8px', overflow: 'hidden', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', backgroundColor: '#f9f9f9', position: 'relative', maxHeight: '80vh', [theme.breakpoints.down('md')]: { height: '300px', mb: 4 } }}>
              <Box sx={{ width: '100%' }}>
                {wsdata && <PlansetChatScreen req_id={wsdata?.data?.jobId} />}
              </Box>
            </Box>
          )}
        </>
      )}
    </Box>
  );
};

export default PlansetDataDisplay;
