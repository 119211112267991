import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

// Async thunk to call the chat message API
export const sendMessage = createAsyncThunk(
  'chat/sendMessage',
  async ({ message, jobId }, { rejectWithValue }) => {
    try {

      // const response = await axios.post('http://general-staging.framesense.ai/api/v1/chat/message', {
      const response = await axios.post('https://general.framesense.ai/api/v1/chat/message', {
        message,
        jobId,
      });

      return response.data; // return data to be used in the fulfilled state
    } catch (error) {
      // Return error data to be handled by the rejected state
      return rejectWithValue(error.response?.data || 'An error occurred');
    }
  }
);

const chatSlice = createSlice({
  name: 'chat',
  initialState: {
    loading: false,
    response: null,
    error: null,
  },
  reducers: {
    // Optionally add additional reducers here if needed
    resetGenralChat: (state) => {
      state.loading = null;
      state.response = null;
      state.error = null;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(sendMessage.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(sendMessage.fulfilled, (state, action) => {
        state.loading = false;
        state.response = action.payload; // API response
        state.error = null;
      })
      .addCase(sendMessage.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload; // Error message from rejectWithValue
      });
  },
});

export const { resetGenralChat } = chatSlice.actions;
export default chatSlice.reducer;
