import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export const processPdf = createAsyncThunk(
  'pdf/processPdf',
  async ({ files, userId,agent_name }, { rejectWithValue }) => {
    const formData = new FormData();
    // formData.append('pdf_file', file);
    files.forEach((file) => {
      formData.append('pdf_files', file); // Use `pdf_files` to match the API requirements
    });
    formData.append('user_id', userId);
    formData.append('agent_name', agent_name);
    try {
      // const response = await axios.post('http://127.0.0.1:8000/api/process-pdf/', formData);

      console.log("@@@@@@@@@@@@",process.env.REACT_APP_OPENAI_BASE_URL)
      const response = await axios.post(`${process.env.REACT_APP_OPENAI_BASE_URL}/process-pdf/`, formData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

const pdfSlice = createSlice({
  name: 'pdf',
  initialState: {
    selectedFile: null,
    responseData: null,
    loading: false,
    error: null,
  },
  reducers: {
    setSelectedFile: (state, action) => {
      state.selectedFile = action?.payload;
      state.responseData = null; // Reset previous response
      state.error = null; // Reset previous errors
    },
    reset: (state) => {
      state.selectedFile = null;
      state.responseData = null;
      state.loading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(processPdf.pending, (state) => {
        state.loading = true;
      })
      .addCase(processPdf.fulfilled, (state, action) => {
        state.loading = false;
        state.responseData = action?.payload;
      })
      .addCase(processPdf.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.payload;
      });
  },
});

export const { setSelectedFile, reset } = pdfSlice.actions;

export default pdfSlice.reducer;
