import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  TextField,
  Typography,
  Paper,
  List,
  ListItem,
  Divider,
  IconButton,
  ImageList,
  ImageListItem,
  Skeleton,
  useTheme,
} from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useDispatch, useSelector } from 'react-redux';
import { sendMessage } from '../redux/newchat/generalChatSlice';

const ChatScreen = ({ wsdata }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [inputValue, setInputValue] = useState('');
  const { loading, response, error } = useSelector((state) => state.generalChat); // Access chat slice state
  const [messages, setMessages] = useState([]);
  const [isLoadingResponse, setIsLoadingResponse] = useState(false); // State for Skeleton loader
  const messagesEndRef = useRef(null); // Ref for auto-scrolling

  // Use the jobId from wsdata, passed as a prop from the parent
  const jobId = wsdata?.data?.jobId;

  // Scroll to bottom when messages update
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(scrollToBottom, [messages]);

  // When a new response comes in, add it to messages
  useEffect(() => {
    if (response) {
      const botResponse = {
        question: response?.data?.question || 'No question provided',
        answer: response?.data?.answer || 'No answer provided',
        relevantImages: response?.data?.relevantImages || [],
        sender: 'bot',
      };
      setMessages((prevMessages) => {
        // Add bot response without repeating the question
        return prevMessages.map((msg) =>
          msg.sender === 'user' && msg.text === botResponse.question
            ? { ...msg, answer: botResponse.answer, relevantImages: botResponse.relevantImages }
            : msg
        );
      });
      setIsLoadingResponse(false); // Hide Skeleton loader once response is received
    }
  }, [response, jobId]);

  // Handle sending a message
  const handleSendMessage = () => {
    if (inputValue.trim() !== '' && jobId) {
      const userMessage = { text: inputValue, sender: 'user' };
      setMessages((prevMessages) => [...prevMessages, userMessage]);

      // Show Skeleton loader
      setIsLoadingResponse(true);
      // Dispatch sendMessage thunk with message and jobId
      dispatch(sendMessage({ message: inputValue, jobId }));

      setInputValue('');
    }
  };

  return (
    <Box sx={{ padding: 4, height: '100vh', display: 'flex', flexDirection: 'column', backgroundColor: theme.palette.background.default }}>
      <Paper
        elevation={3}
        sx={{
          flex: 1,
          overflow: 'auto',
          padding: 2,
          display: 'flex',
          flexDirection: 'column',
          borderRadius: 2,
          backgroundColor: theme.palette.background.paper,
        }}
      >
        <List>
          {messages.map((msg, index) => (
            <React.Fragment key={index}>
              {/* Display user questions */}
              {msg.sender === 'user' && (
                <>
                  <ListItem alignItems="flex-start">
                    <Typography variant="body1" fontWeight="bold" color={theme.palette.text.primary}>
                      Question: {msg.text}
                    </Typography>
                  </ListItem>
                  {msg.answer && (
                    <ListItem alignItems="flex-start">
                      <Typography variant="body2" color={theme.palette.text.secondary}>
                        Answer: {msg.answer}
                      </Typography>
                    </ListItem>
                  )}
                  {msg.relevantImages && msg.relevantImages.length > 0 && (
                    <ImageList cols={3} gap={8} sx={{ mt: 1 }}>
                      {msg.relevantImages.map((url, imgIndex) => (
                        <ImageListItem key={imgIndex}>
                          <img
                            src={url}
                            alt={`Relevant image ${imgIndex + 1}`}
                            loading="lazy"
                            style={{ width: '100%', height: 'auto' }}
                          />
                        </ImageListItem>
                      ))}
                    </ImageList>
                  )}
                  <Divider />
                </>
              )}
            </React.Fragment>
          ))}

          {/* Show Skeleton loader while waiting for response */}
          {isLoadingResponse && (
            <ListItem alignItems="flex-start">
              <Box width="100%">
                <Skeleton variant="text" height={20} width="40%" />
                <Skeleton variant="text" height={20} width="60%" />
                <Skeleton variant="rectangular" height={118} width="100%" />
              </Box>
            </ListItem>
          )}

          {/* Reference div for auto-scrolling */}
          <div ref={messagesEndRef} />
        </List>
      </Paper>

      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          mt: 2,
          borderRadius: '20px',
          backgroundColor: theme.palette.background.paper,
          padding: '10px',
          boxShadow: 3,
          position: 'relative',
          width: '100%',
        }}
      >
        <TextField
          variant="outlined"
          fullWidth
          placeholder="Type your message..."
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          onKeyPress={(e) => e.key === 'Enter' && handleSendMessage()}
          sx={{
            borderRadius: '20px',
            marginLeft: 1,
            marginRight: 1,
            backgroundColor: theme.palette.background.default,
            color: theme.palette.text.primary,
          }}
          InputProps={{
            style: {
              color: theme.palette.text.primary,
            },
          }}
        />

        <IconButton
          onClick={handleSendMessage}
          sx={{
            borderRadius: '50%',
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.getContrastText(theme.palette.primary.main),
            '&:hover': {
              backgroundColor: theme.palette.primary.dark,
            },
          }}
        >
          <ArrowForwardIcon />
        </IconButton>
      </Box>
    </Box>
  );
};

export default ChatScreen;
