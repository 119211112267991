import React, { useEffect, useState } from 'react';
import { Box, Typography, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { processPlansetPdf, setSelectedPlansetFile, resetPlansetPdfData } from '../../redux/survey/planSetAnalyzerSlice';
import PlansetDataDisplay from '../../components/PlansetDataDisplay';
import SkeletonLoader from '../../components/SkeletonLoader';
import Upload from '@mui/icons-material/Upload';
import AddIcon from '@mui/icons-material/Add';
import { useTheme } from '@mui/material/styles';

const PlanSetAnalyzer = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const theme = useTheme(); 
    const { selectedPlanSetFile, plansetPdfresponseData, loading, error } = useSelector((state) => state.planSetAnalyzer || {});
    const [pdfFile, setPdfFile] = useState(null);
    const [askQuestionsOpen, setAskQuestionsOpen] = useState(false);
    const [isChatScreenOpen, setIsChatScreenOpen] = useState(false);


    const haindileNewchat = () => {
        navigate('/');
    }

    const handleToggleChat = () => {
        setIsChatScreenOpen(!isChatScreenOpen);
    };

    const handleFileChange = (event) => {
        const storedUser = JSON.parse(localStorage.getItem('user'));
        const file = event.target.files[0];
        if (file) {
            if (file.type === 'application/pdf') {
                setPdfFile(file);
                dispatch(setSelectedPlansetFile(file)); // Update Redux state when a file is selected

                // Call the processPdf action immediately after file selection
                const userId = storedUser?._id?.slice(-8);  // Replace with actual user ID
                dispatch(processPlansetPdf({ file: file, userId })); // Automatically upload the file
            } else {
                alert('Please select a PDF file.');
                event.target.value = '';
            }
        }
    };

    // Reset state on component unmount
    useEffect(() => {
        return () => {
            dispatch(resetPlansetPdfData());
        };
    }, [dispatch]);

    return (
        <Box sx={{ position: 'relative', textAlign: 'center' }}>


            <div>
                <Typography
                    variant="h6"
                    sx={{
                        position: 'absolute',
                        top: "-150px",
                        left: "2px", // Adjust this value to position text correctly
                        fontWeight: 'bold',
                        color: (theme) => theme.palette.mode === 'dark' ? '#FFFFFF' : '#1F2937',
                    }}
                >
                    Template/Planset analyzer
                </Typography>

            </div>

            {!loading && !plansetPdfresponseData && (

                <Box
                    sx={{
                        flex: 1,
                        // padding: 4,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginTop: "100px"
                        // border: '1px dashed grey',
                    }}
                >
                    <Box
                        sx={{
                            textAlign: 'center',
                            marginBottom: 4,
                        }}
                    >
                        <Box
                            sx={{
                                width: 96, // 24 * 4 (MUI spacing unit)
                                height: 96,
                                backgroundColor: theme.palette.mode === 'dark' ? '#616161' : '#E0E0E0',
                                borderRadius: '50%',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                marginBottom: 2,
                                marginX: 'auto',
                            }}
                        >
                            <Upload sx={{ fontSize: '48px', color: theme.palette.mode === 'dark' ? '#B0BEC5' : '#9E9E9E' }} />
                        </Box>
                        <Typography variant="h5" sx={{ fontWeight: 'bold', marginBottom: 1, color: theme.palette.mode === 'dark' ? '#FFFFFF' : '#1F2937' }}>
                            {selectedPlanSetFile ? 'Ready to analyze' : 'No content to display'}
                        </Typography>
                        <Typography variant="body2" sx={{ color: theme.palette.mode === 'dark' ? '#B0BEC5' : '#6B7280', marginBottom: 2 }}>
                            Upload a file to start analyzing your planset data
                        </Typography>
                        {selectedPlanSetFile && (
                            <Typography variant="body2" sx={{ color: theme.palette.mode === 'dark' ? '#B0BEC5' : '#6B7280', marginBottom: 2 }}>
                                Selected file: {selectedPlanSetFile.name}
                            </Typography>
                        )}
                        {error && (
                            <Typography variant="body2" color="error" sx={{ marginBottom: 2 }}>
                                {error}
                            </Typography>
                        )}
                        <Box sx={{ mt: 3 }}>
                            <input
                                type="file"
                                accept="application/pdf"
                                onChange={handleFileChange}
                                style={{ display: 'none' }} // Hide the file input
                                id="file-upload"
                            />
                            <label htmlFor="file-upload">
                                <Button
                                    variant="contained"
                                    component="span"
                                    // startIcon={<AddIcon />} 
                                    sx={{
                                        padding: "8px",
                                        borderRadius: '5px',
                                        backgroundColor: theme.palette.mode === 'dark' ? '#1976D2' : '#3B82F6',
                                        color: '#FFFFFF',
                                        '&:hover': {
                                            backgroundColor: theme.palette.mode === 'dark' ? '#2196F3' : '#2563EB',
                                        },
                                    }}
                                >
                                    Choose File
                                </Button>
                            </label>
                        </Box>
                    </Box>
                </Box>
            )}
            {loading && <SkeletonLoader />}
            {plansetPdfresponseData && (
                <>
                    <PlansetDataDisplay
                        selectedFile={selectedPlanSetFile}
                        wsdata={plansetPdfresponseData}
                        askQuestionsOpen={askQuestionsOpen}
                        setAskQuestionsOpen={setAskQuestionsOpen}
                        isChatScreenOpen={isChatScreenOpen}
                    />
                </>
            )}
            {plansetPdfresponseData && askQuestionsOpen && (
                <Button
                    sx={{
                        position: 'fixed',
                        top: "250px !important",
                        right: "0px !important",
                        zIndex: 1000,
                        backgroundColor: '#3f51b5',
                        color: '#fff',
                        '&:hover': {
                            backgroundColor: '#303f9f',
                        },
                    }}
                    onClick={handleToggleChat}
                >
                    {isChatScreenOpen ? 'Close Chat' : 'Open Chat'}
                </Button>
            )}

            {/* <Box
 sx={{
  position: 'fixed', 
  top: 76, 
  right: 16, 
  zIndex: 1000, 
}}
  
>
  <Button
    onClick={haindileNewchat} 
    variant="contained"
    sx={{
      backgroundColor: '#676464', 
      color: theme.palette.mode === 'dark' ? '#FFFFFF' : '#000000', 
      '&:hover': {
        backgroundColor: '#676464', 
        color: theme.palette.mode === 'dark' ? '#000000' : '#FFFFFF',
      },
      borderRadius: '50%', 
      minWidth: '60px',
      minHeight: '60px', 
      padding: 0, 
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      boxShadow: 'none', 
      transition: 'background-color 0.3s, color 0.3s',
    }}
  >
    <AddIcon /> 
  </Button>
</Box> */}
        </Box>
    );
};

export default PlanSetAnalyzer;
