import React from 'react';
import { Box, Skeleton } from '@mui/material';

const SkeletonLoader = () => {
  return (
    <Box sx={{ width: '100%', padding: '16px' }}>
      {Array.from(new Array(5)).map((_, index) => (
        <Box key={index} sx={{ marginBottom: '24px' }}>
          <Skeleton variant="text" width="80%" height={40} />
          <Skeleton variant="text" width="60%" height={30} />
          <Skeleton variant="rectangular" height={200} sx={{ marginTop: '16px' }} />
          <Skeleton variant="text" width="90%" height={30} sx={{ marginTop: '16px' }} />
        </Box>
      ))}
    </Box>
  );
};

export default SkeletonLoader;
