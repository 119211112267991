import React, { useState, useContext } from 'react';
import {
  Container,
  Box,
  TextField,
  Button,
  Typography,
  CircularProgress,
  Link,
  Card,
  InputAdornment,
  IconButton,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { validateEmail, validatePassword } from '../utils/validation';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../contexts/AuthContext';
import { signupUser, resetState } from '../redux/auth/signupSlice';
const Signup = () => {
  const navigate = useNavigate();
  const { login } = useContext(AuthContext);
  const dispatch = useDispatch();
  const { user, singuploading, error } = useSelector((state) => state.signup);

  // State for form data
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
    confirmPassword: '',
  });

  // State for errors
  const [errors, setErrors] = useState({
    name: '',
    email: '',
    password: '',
    confirmPassword: '',
    form: '',
  });

  // State for loading
  const [loading, setLoading] = useState(false);

  // State for password visibility
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  React.useEffect(() => {
    if (user) {
      navigate('/');
    }
  }, [user, navigate]);

  React.useEffect(() => {
    if (error) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        form: error.message || 'Failed to Signup',
      }));
    }
  }, [error]);

  // Handle input changes with real-time validations
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    // Real-time validations
    switch (name) {
      case 'name':
        setErrors((prevErrors) => ({
          ...prevErrors,
          name: value.length >= 3 ? '' : 'Name must be at least 3 characters long.',
        }));
        break;
      case 'email':
        setErrors((prevErrors) => ({
          ...prevErrors,
          email: validateEmail(value) ? '' : 'Please enter a valid email address.',
        }));
        break;
      case 'password':
        setErrors((prevErrors) => ({
          ...prevErrors,
          password: validatePassword(value) ? '' : 'Password must be at least 8 characters long.',
        }));

        if (formData.confirmPassword && value !== formData.confirmPassword) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            confirmPassword: 'Passwords do not match!',
          }));
        } else if (formData.confirmPassword && value === formData.confirmPassword) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            confirmPassword: '',
          }));
        }
        break;
      case 'confirmPassword':
        setErrors((prevErrors) => ({
          ...prevErrors,
          confirmPassword: value === formData.password ? '' : 'Passwords do not match!',
        }));
        break;
      default:
        break;
    }

    // Clear form-level error when user starts correcting inputs
    setErrors((prevErrors) => ({
      ...prevErrors,
      form: '',
    }));
  };

  // Determine if the form is valid
  const isFormValid =
    formData.name &&
    formData.email &&
    formData.password &&
    formData.confirmPassword &&
    !errors.name &&
    !errors.email &&
    !errors.password &&
    !errors.confirmPassword;

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    const newErrors = {};

    if (!validateEmail(formData.email)) {
      newErrors.email = 'Please enter a valid email address.';
    }

    if (!validatePassword(formData.password)) {
      newErrors.password = 'Password must be at least 8 characters long.';
    }

    if (formData.password !== formData.confirmPassword) {
      newErrors.confirmPassword = 'Passwords do not match!';
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        ...newErrors,
        form: 'Please correct the errors above before submitting.',
      }));
      return;
    }

    setLoading(true);
    setErrors((prevErrors) => ({
      ...prevErrors,
      form: '',
    }));

    try {
      await dispatch(signupUser({ email: formData.email, password: formData.password,name:formData.name })).unwrap();
    } catch (err) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        form: 'An error occurred during signup. Please try again.',
      }));
    } finally {
      setLoading(false);
    }
  };

  const handleClickShowPassword = () => {
    setShowPassword((prev) => !prev);
  };

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword((prev) => !prev);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
        backgroundColor: '#f0f0f0',
      }}
    >
      <Container maxWidth="sm">
        <Card sx={{ padding: 4, boxShadow: 3, borderRadius: 2 }}>
          <Typography variant="h4" component="h1" align="center" gutterBottom>
            Sign Up
          </Typography>
          <Box component="form" onSubmit={handleSubmit} sx={{ mt: 2 }}>
            {/* Full Name Field */}
            <TextField
              label="Full Name"
              variant="outlined"
              fullWidth
              required
              margin="normal"
              name="name"
              value={formData.name}
              onChange={handleChange}
              error={Boolean(errors.name)}
              helperText={errors.name}
            />

            {/* Email Field */}
            <TextField
              label="Email"
              variant="outlined"
              fullWidth
              required
              margin="normal"
              name="email"
              value={formData.email}
              onChange={handleChange}
              error={Boolean(errors.email)}
              helperText={errors.email}
            />

            {/* Password Field with Show/Hide */}
            <TextField
              label="Password"
              type={showPassword ? 'text' : 'password'}
              variant="outlined"
              fullWidth
              required
              margin="normal"
              name="password"
              value={formData.password}
              onChange={handleChange}
              error={Boolean(errors.password)}
              helperText={errors.password}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label={showPassword ? 'Hide password' : 'Show password'}
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            {/* Confirm Password Field with Show/Hide */}
            <TextField
              label="Confirm Password"
              type={showConfirmPassword ? 'text' : 'password'}
              variant="outlined"
              fullWidth
              required
              margin="normal"
              name="confirmPassword"
              value={formData.confirmPassword}
              onChange={handleChange}
              error={Boolean(errors.confirmPassword)}
              helperText={errors.confirmPassword}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label={showConfirmPassword ? 'Hide password' : 'Show password'}
                      onClick={handleClickShowConfirmPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            {/* Form-Level Error */}
            {errors.form && (
              <Typography color="error" sx={{ mt: 1 }}>
                {errors.form}
              </Typography>
            )}

            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              sx={{ mt: 2, padding: 1.5, 
                borderRadius:4, bgcolor: isFormValid && !loading ? 'black' : 'primary.main', color: 'white'
               }}
              disabled={!isFormValid || loading}
            >
              {loading ? <CircularProgress size={24} color="inherit" /> : 'Sign Up'}
            </Button>
          </Box>

          {/* Already have an account? Login Link */}
          <Box sx={{ mt: 2, textAlign: 'center' }}>
            <Typography variant="body2">
              Already have an account?{' '}
              <Link href="/login" variant="body2">
                Login
              </Link>
            </Typography>
          </Box>
        </Card>
      </Container>
    </Box>
  );
};

export default Signup;
