import React, { useState, useEffect } from 'react';
import {
  Card,
  CardContent,
  Typography,
  IconButton,
  Box,
  Grid,
  Collapse,
  Button,
  useTheme,
  TextField,
} from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import {
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon,
  Edit as EditIcon,
  Save as SaveIcon,
} from '@mui/icons-material';
import ImageCarousel from './ImageCarousel';
import { useLocation } from 'react-router-dom';
import ServeyChatScreen from './ServeyChatHistoryScreen';
import { useDispatch ,useSelector} from 'react-redux';
import { processSurveyChat } from '../redux/survey/Surveychatslice';
import * as XLSX from 'xlsx';

import { fetchUploadHistory, resetUploadHistory } from '../redux/survey/uploadHistorySlice';
const categoryColors = {
  Structural: '#1976d2',
  Polamber: '#388e3c',
  Electrical: '#f57c00',
  Measurements: '#d32f2f',
  General: '#7b1fa2',
  SolarPanels: '#ffa000',
};

const SurveyDataDisplay = ({id, wsdata, askQuestionsOpen, setAskQuestionsOpen, isChatScreenOpen }) => {
  const dispatch = useDispatch();
  const [chatData, setChatData] = useState([]);
  const location = useLocation();
  const [prevLocation, setPrevLocation] = useState(location.pathname);
  const { searchstabs } = useSelector((state) => state.uploadHistory);

  const { chatloading, chaterror, chatResponseData } = useSelector((state) => state.surveychat || {});
  const [categorizedData, setCategorizedData] = useState({
    Electrical: [],
    SolarPanels: [],
    Measurements: [],
    Structural: [],
    General: [],
    Others: [],
  });
  const [chatResponseDatas, setchatResponseDatas] = useState(null);
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [editMode, setEditMode] = useState(null);
  const [editedAnswer, setEditedAnswer] = useState('');
  const theme = useTheme();

  useEffect(() => {
    if (location.pathname !== prevLocation) {
      setPrevLocation(location.pathname);
    }
  }, [location, prevLocation]);
  

  useEffect(() => {
    setAskQuestionsOpen(true);
    setchatResponseDatas(wsdata?.data?.chatOutputs);
    categorizeData(wsdata?.data?.outputs);
  }, [wsdata]);

 
  

  useEffect(() => {
    if (askQuestionsOpen) {
      
      const storedUser = JSON.parse(localStorage.getItem('user'));
      const userId = storedUser?._id;
      dispatch(processSurveyChat({ userId,req_id:id }));
    }

  }, [askQuestionsOpen]);

  

  // Define electrical categories outside the function for optimization
const electricalCategories = new Set([
  "Utility_Meter",
  "Electrical_Panel_1",
  "Electrical_Panel_2",
  "ServiceDisconnect",
  "Electrical_Labels"
]);

const categorizeData = (data) => {
  const initialCategories = ["Electrical", "SolarPanels", "Measurements", "Structural", "General", "Others"];
  const categorized = Object.fromEntries(initialCategories.map(cat => [cat, []]));

  if (data) {
    data.forEach(item => {
      const category = categorized.hasOwnProperty(item.category)
        ? item.category
        : electricalCategories.has(item.category)
        ? "Electrical"
        : "Others";

      categorized[category].push(item);
    });
  }

  setCategorizedData(categorized);
};

  const handleToggle = (item) => {
    setSelectedQuestion(selectedQuestion === item ? null : item);
  };

  const parseFinalAnswer = (finalAnswerStr) => {
    try {
      const cleanedStr = finalAnswerStr.replace(/```json/, '').replace(/```/, '').trim();
      const parsed = JSON.parse(cleanedStr);
      return {
        answer: parsed.OpenAI_Answer || 'No answer provided.',
        confidence: parsed.confidence_score || 'N/A',
      };
    } catch (error) {
      return {
        answer: 'Invalid answer format.',
        confidence: 'N/A',
      };
    }
  };

  const handleEdit = (item) => {
    setEditMode(item._id); // Set the current item as being edited
    setEditedAnswer(parseFinalAnswer(item.final_answer).answer); // Set the answer in the input field
  };

  const handleSave = (item) => {
    // Create a deep copy of the categorized data to ensure immutability
    setCategorizedData(prevData => {
      const updatedData = { ...prevData };
      const electricalCategories = [
        "Utility_Meter",
        "Electrical_Panel_1",
        "Electrical_Panel_2",
        "ServiceDisconnect",
        "Electrical_Labels"
      ];
  
      const category = electricalCategories.includes(item.category) ? "Electrical" : item.category;
      console.log("***********^^^",updatedData);

      // Find the index of the item being edited
      const index = updatedData[category].findIndex(entry => entry._id === item._id);

      if (index !== -1) {
        // Create a new object for the item being updated
        const updatedItem = {
          ...updatedData[category][index],
          final_answer: JSON.stringify({ OpenAI_Answer: editedAnswer, confidence_score: 'N/A' }),
        };

        // Replace the old item with the updated one
        updatedData[category][index] = updatedItem;
      }

      return updatedData; // Return the updated state
    });

    setEditMode(null); // Exit edit mode
  };

  const downloadChatDataAsExcel = () => {
    let flatData = [];

    Object.keys(categorizedData).forEach(category => {
      const entries = categorizedData[category];
      if (entries && entries.length > 0) {
        entries.forEach(entry => {
          let parsedAnswer;
          try {
            parsedAnswer = JSON.parse(entry.final_answer.replace(/```json/g, '').replace(/```/g, ''));
          } catch (error) {
            parsedAnswer = { OpenAI_Answer: entry.final_answer };
          }

          flatData.push({
            category: entry.category,
            question: entry.question,
            final_answer: parsedAnswer.OpenAI_Answer || entry.final_answer,
            confidence_score: parsedAnswer.confidence_score || '',
          });
        });
      }
    });

    const worksheet = XLSX.utils.json_to_sheet(flatData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'ChatData');
    XLSX.writeFile(workbook, 'Survey_Chat_Data.xlsx');
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
        margin: '0 auto',
        padding: '0px !important',
        maxWidth: '1150px',
        width: '100%',
        flexDirection: { xs: 'column', md: 'row' },
      }}
    >
      <Card
     
        sx={{
          width: { xs: '100%', md: isChatScreenOpen ? '60%' : '90%' },
          height: 'auto',
          overflowY: 'auto',
          mr: { md: 2 },
          mb: { xs: 4, md: 0 },
          maxHeight: '80vh',
          position: 'relative',
        }}
      >
        <CardContent>
        <Box
    className="bg-white dark:bg-gray-800 shadow rounded-lg p-6"
    display="flex"
    justifyContent="space-between"
    alignItems="center"
    mb={4}
  >
    <Typography variant="h5" fontWeight="bold" textAlign="left">
      Survey Information
    </Typography>
    <Button
      onClick={downloadChatDataAsExcel} // Adjust the function if necessary
      startIcon={<DownloadIcon />}
      variant="contained"
      color="primary"
    >
      Download Report
    </Button>
  </Box>
          {Object.keys(categorizedData).map(category => (
            categorizedData[category].length > 0 && (
              <Box key={category} mb={4}>
                <Typography
                  variant="h6"
                  fontWeight="bold"
                  sx={{
                    color: categoryColors[category] || '#000',
                    textAlign: 'left',
                    mb: 2,
                  }}
                >
                  {category}
                </Typography>
                {categorizedData[category].map((item) => {
                  const isSelected = selectedQuestion === item;
                  const { answer } = parseFinalAnswer(item.final_answer);
                  return (
                    <Box key={item._id} mb={2} sx={{ borderBottom: '1px solid #e0e0e0', pb: 2 }}>
                      <Grid container spacing={2} alignItems="flex-start">
                        <Grid item xs={12}>
                          <Box display="flex" alignItems="center" justifyContent="space-between">
                            <Typography variant="body1" fontWeight="bold" textAlign="left">
                              {`Q: ${item.question}`}
                            </Typography>
                            <Box>
                              {editMode === item._id ? (
                                <IconButton onClick={() => handleSave(item)} aria-label="Save answer">
                                  <SaveIcon />
                                </IconButton>
                              ) : (
                                <IconButton onClick={() => handleEdit(item)} aria-label="Edit answer">
                                  <EditIcon />
                                </IconButton>
                              )}
                              <IconButton
                                onClick={() => handleToggle(item)}
                                aria-label={isSelected ? 'Collapse answer' : 'Expand answer'}
                                sx={{
                                  transition: 'transform 0.2s',
                                  '&:hover': {
                                    transform: 'scale(1.1)',
                                  },
                                }}
                              >
                                {isSelected ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                              </IconButton>
                            </Box>
                          </Box>
                          <Collapse in={!isSelected}>
                            <Box display="flex" alignItems="center" mt={1}>
                              {editMode === item._id ? (
                                <TextField
                                  variant="outlined"
                                  value={editedAnswer}
                                  onChange={(e) => setEditedAnswer(e.target.value)}
                                  fullWidth
                                  multiline
                                  rows={3}
                                  placeholder="Edit your answer here"
                                />
                              ) : (
                                <Typography variant="body2" textAlign="left">
                                  {`A: ${answer}`}
                                </Typography>
                              )}
                            </Box>
                          </Collapse>
                          <Collapse in={isSelected}>
                            <Box mt={1}>
                              <Typography variant="body2" sx={{ textAlign: 'left', flexGrow: 1 }}>{answer}</Typography>
                              <Box mt={2}>
                                {item.image_urls && item.image_urls.length > 0 && (
                                  <ImageCarousel images={item.image_urls} />
                                )}
                              </Box>
                            </Box>
                          </Collapse>
                        </Grid>
                      </Grid>
                    </Box>
                  );
                })}
              </Box>
            )
          ))}
        </CardContent>
      </Card>

      {/* {isChatScreenOpen && (
        <ServeyChatScreen
          chatData={chatResponseData}
          setChatData={setChatData}
          askQuestionsOpen={askQuestionsOpen}
          setAskQuestionsOpen={setAskQuestionsOpen}
        />
      )} */}

{isChatScreenOpen && (
              <Box
              sx={{
                width: { xs: '100%', md: '35%' },
                height: 'auto',
                borderRadius: '8px',
                overflow: 'hidden',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'center',
                backgroundColor: '#f9f9f9',
                position: 'relative',
                maxHeight: '80vh',
                [theme.breakpoints.down('md')]: {
                  height: '300px',
                  mb: 4,
                },
              }}
            >
            
              <Box  sx={{width:'100%'}}>
                {chatResponseDatas && <ServeyChatScreen setChatData ={setChatData} req_id={id} conestiondata={chatResponseData} chatResposeData={chatResponseDatas} />}
              </Box>

            </Box>
            )}
    </Box>
  );
};

export default SurveyDataDisplay;
