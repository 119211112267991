import React, { useRef, useState } from 'react';
import { Box, IconButton, Typography, Modal, useTheme, useMediaQuery } from '@mui/material';
import Slider from 'react-slick';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import 'slick-carousel/slick/slick.css'; // Import Slick CSS
import 'slick-carousel/slick/slick-theme.css'; // Import Slick Theme CSS
import CancelIcon from '@mui/icons-material/Cancel';

const ImageCarousel = ({ images }) => {
  const sliderRef = useRef(null); // Reference to the slider
  const theme = useTheme(); // Access the current theme
  const isDarkMode = theme.palette.mode === 'dark';
  const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Check if screen size is mobile
  const [open, setOpen] = useState(false); // State for modal open/close
  const [selectedIndex, setSelectedIndex] = useState(0); // State for the currently selected image index

  const settings = {
    dots: true, // Show navigation dots
    infinite: true, // Infinite looping
    speed: 500, // Transition speed in ms
    slidesToShow: 1, // Number of slides to show at once
    slidesToScroll: 1, // Number of slides to scroll on navigation
    autoplay: true, // Enable autoplay
    autoplaySpeed: 3000, // Autoplay speed in ms (3 seconds)
    pauseOnHover: true, // Pause autoplay on hover
    arrows: false, // Disable default arrows as we'll use custom buttons
    adaptiveHeight: true, // Adjust slide height based on image
  };

  // Handle case where images array is empty or undefined
  if (!images || images.length === 0) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          backgroundColor: isDarkMode ? '#333' : '#f0f0f0'
        }}
      >
        <Typography
          variant="subtitle1"
          color={isDarkMode ? 'grey.300' : 'textSecondary'}
        >
          No images available.
        </Typography>
      </Box>
    );
  }

  // Handle modal open/close
  const handleImageClick = (index) => {
    setSelectedIndex(index);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedIndex(0);
  };

  const handleNext = () => {
    setSelectedIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const handlePrev = () => {
    setSelectedIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
  };

  return (
    <Box
      sx={{
        position: 'relative',
        width: '100%',
        height: '100%',
        backgroundColor: isDarkMode ? '#121212' : 'transparent'
      }}
    >
      <Slider ref={sliderRef} {...settings}>
        {images.map((image, index) => (
          <Box
            key={image.id || index}
            sx={{
              position: 'relative',
              paddingTop: isMobile ? '75%' : '56.25%', // Adjust for mobile (closer to square aspect)
              backgroundColor: isDarkMode ? '#1e1e1e' : '#f0f0f0',
              border: '5px solid', // Create a frame effect
              borderColor: isDarkMode ? '#fff' : '#000',
              borderRadius: '10px',
              overflow: 'hidden', // Prevent overflow from borders
            }}
            onClick={() => handleImageClick(index)} // Open modal on image click
          >
            <img
              src={image}
              alt={`Slide ${index + 1}`}
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                objectFit: 'contain', // Ensures the entire image is visible
                backgroundColor: isDarkMode ? '#1e1e1e' : '#f0f0f0',
                opacity: 0,
                transition: 'opacity 0.5s ease-in-out',
              }}
              onLoad={(e) => {
                e.target.style.opacity = 1;
              }}
              onError={(e) => {
                e.target.style.display = 'none';
              }}
            />
          </Box>
        ))}
      </Slider>

      {/* Custom Previous Button */}
      <IconButton
        onClick={() => sliderRef.current.slickPrev()} // Go to the previous slide
        sx={{
          position: 'absolute',
          top: '50%',
          left: isMobile ? '5px' : '10px', // Adjust for mobile
          transform: 'translateY(-50%)',
          zIndex: 1,
          backgroundColor: isDarkMode ? 'rgba(255, 255, 255, 0.2)' : 'rgba(255, 255, 255, 0.7)',
          color: isDarkMode ? '#fff' : '#000',
          '&:hover': {
            backgroundColor: isDarkMode ? 'rgba(255, 255, 255, 0.4)' : 'rgba(255, 255, 255, 1)',
          },
          padding: isMobile ? '6px' : '8px', // Smaller padding for mobile
        }}
        aria-label="Previous Slide"
      >
        <ArrowBackIcon />
      </IconButton>

      {/* Custom Next Button */}
      <IconButton
        onClick={() => sliderRef.current.slickNext()} // Go to the next slide
        sx={{
          position: 'absolute',
          top: '50%',
          right: isMobile ? '5px' : '10px', // Adjust for mobile
          transform: 'translateY(-50%)',
          zIndex: 1,
          backgroundColor: isDarkMode ? 'rgba(255, 255, 255, 0.2)' : 'rgba(255, 255, 255, 0.7)',
          color: isDarkMode ? '#fff' : '#000',
          '&:hover': {
            backgroundColor: isDarkMode ? 'rgba(255, 255, 255, 0.4)' : 'rgba(255, 255, 255, 1)',
          },
          padding: isMobile ? '6px' : '8px', // Smaller padding for mobile
        }}
        aria-label="Next Slide"
      >
        <ArrowForwardIcon />
      </IconButton>

      {/* Modal for Image Zoom */}
      <Modal
        open={open}
        onClose={handleClose}
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      >
        <Box
          sx={{
            position: 'relative',
            width: isMobile ? '90%' : '80%', // Adjust for mobile
            height: isMobile ? '70%' : '80%', // Adjust for mobile
            backgroundColor: 'white',
            borderRadius: '10px',
            boxShadow: 24
          }}
        >
          <img
            src={images[selectedIndex]} // Show the selected image in modal
            alt="Zoomed Image"
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'contain',
            }}
          />
          <IconButton
            onClick={handlePrev} // Navigate to previous image
            sx={{
              position: 'absolute',
              top: '50%',
              left: '10px',
              transform: 'translateY(-50%)',
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              color: 'white',
            }}
            aria-label="Previous"
          >
            <ArrowBackIcon />
          </IconButton>
          <IconButton
            onClick={handleNext} // Navigate to next image
            sx={{
              position: 'absolute',
              top: '50%',
              right: '10px',
              transform: 'translateY(-50%)',
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              color: 'white',
            }}
            aria-label="Next"
          >
            <ArrowForwardIcon />
          </IconButton>
          <IconButton
            onClick={handleClose}
            sx={{
              position: 'absolute',
              padding: "8px",
              borderRadius: "26%",
              top: '10px',
              right: '10px',
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              color: 'white',
            }}
            aria-label="Close"
          >
            <CancelIcon />
          </IconButton>
        </Box>
      </Modal>
    </Box>
  );
};

export default ImageCarousel;
