// src/redux/auth/authSlice.js

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

// Async thunk for user login
export const loginUser = createAsyncThunk(
  'auth/loginUser',
  async ({ email, password }, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/auth/login`, {
        email,
        password,
      });
      // Assuming the API returns a token or user data
      return response.data;
    } catch (error) {
      if (error?.response && error?.response?.data) {
        return rejectWithValue(error?.response?.data);
      } else {
        return rejectWithValue({ message: 'Network Error' });
      }
    }
  }
);


export const loginwithGoogle = createAsyncThunk(
  'auth/loginwithGoogle',
  async ({ token }, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/auth/googleLogin`, {
        token
      });
      // Assuming the API returns a token or user data
      return response.data;
    } catch (error) {
      if (error?.response && error?.response?.data) {
        return rejectWithValue(error?.response?.data);
      } else {
        return rejectWithValue({ message: 'Network Error' });
      }
    }
  }
);


const authSlice = createSlice({
  name: 'auth',
  initialState: {
    user: null, // To store user data after successful login
    loading: false,
    error: null,
  },
  reducers: {
    resetError: (state) => {
      state.error = null;
    },
    logoutUser: (state) => {
      state.user = null;
      state.error = null;
      state.loading = false;
      localStorage.removeItem('token');
      localStorage.removeItem('user');
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.loading = false;
        if(action.payload?.status){
          state.user = action.payload?.data; // Adjust based on your API response
          localStorage.setItem('user', JSON.stringify(action?.payload?.data));
          localStorage.setItem('token', action?.payload?.data?.token);
        }
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.payload?.message || 'Failed to login';
      }).addCase(loginwithGoogle.pending, (state) => {
        state.loading = true;
        state.error = null;
      }).addCase(loginwithGoogle.fulfilled, (state, action) => {
        state.loading = false;
        if(action.payload?.status){
          state.user = action.payload?.data; // Adjust based on your API response
          localStorage.setItem('user', JSON.stringify(action?.payload?.data));
          localStorage.setItem('token', action?.payload?.data?.token);
        }
      }).addCase(loginwithGoogle.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.payload?.message || 'Failed to login';
      });
  },
});

export const { resetError, logoutUser } = authSlice.actions;

export default authSlice.reducer;
