// src/redux/slices/signupSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

// Define an async thunk for the signup action
export const signupUser = createAsyncThunk(
  'signup/signupUser',
  async (userData, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/auth/register`, userData);
      return response.data; // Assuming response.data contains the user data
    } catch (error) {
      // Return error message if request fails
      return rejectWithValue(error?.response?.data);
    }
  }
);

// Create the slice
const signupSlice = createSlice({
  name: 'signup',
  initialState: {
    user: null,
    singuploading: false,
    error: null,
  },
  reducers: {
    resetState: (state) => {
      state.user = null;
      state.singuploading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(signupUser.pending, (state) => {
        state.singuploading = true;
        state.error = null;
      })
      .addCase(signupUser.fulfilled, (state, action) => {
        state.singuploading = false;
        state.user = action.payload; // Store the user data
      })
      .addCase(signupUser.rejected, (state, action) => {
        state.singuploading = false;
        state.error = action.payload; // Store error message
      });
  },
});

// Export the action and reducer
export const { resetState } = signupSlice.actions;
export default signupSlice.reducer;
