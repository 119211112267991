import React, { useState, useRef, useEffect } from 'react';
import {
  Card, Stepper, Step, StepLabel,
  CardContent,
  Typography, TextField,
  IconButton,
  Box,
  Grid,
  Collapse, LinearProgress, CircularProgress,
  Skeleton,
  useTheme,
} from '@mui/material';
import * as XLSX from 'xlsx';
import { useWebSocket } from '../contexts/WebSocketContext';
import {
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon,
  Edit as EditIcon,
  Save as SaveIcon,
} from '@mui/icons-material';
import DownloadIcon from '@mui/icons-material/Download';
import ImageCarousel from './ImageCarousel';
import { useDispatch, useSelector } from 'react-redux';
import ServeyChatScreen from './ServeyChatScreen';
import { resetSurveyChat, processSurveyChat } from '../redux/survey/Surveychatslice';

const SurveyDataDisplay = ({ wsdata, id, selectedFile, setAskQuestionsOpen, isChatScreenOpen }) => {
  
  const dispatch = useDispatch();
  const {
    messages,
    loading,
    statusMessage,
    allDataLoaded,
    askQuestionsOpen,
    categorizedData, activeStep, steps,setCategorizedData,editCategoryData
  } = useWebSocket();
  
  const [chatData, setChatData] = useState([]);
  const { chatloading, chaterror, chatResponseData } = useSelector((state) => state.surveychat || {});
  const { searchstabs } = useSelector((state) => state.uploadHistory);

  const { searchstabsResponse } = useSelector((state) => state.uploadHistory)
  const [carouselImages, setCarouselImages] = useState([]);
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [progress, setProgress] = useState(0);
  const searchResponseRef = useRef(searchstabsResponse);
  const chatDataRef = useRef(chatData);
  const categorizedDataRef = useRef(categorizedData[id]);
  const [editMode, setEditMode] = useState(null); // State to manage edit mode
  const [editedAnswer, setEditedAnswer] = useState(''); // State for the edited answer
  const theme = useTheme();
 
  useEffect(() => {console.log("*****",categorizedData,"££££££££",messages)
  }, [categorizedData, messages]);

  useEffect(() => {
    if(askQuestionsOpen[id]){

      setAskQuestionsOpen(askQuestionsOpen[id])
    }
  }, [askQuestionsOpen]);

  

  const downloadChatDataAsExcel = () => {
    let flatData = [];

    Object.keys(categorizedData[id]).forEach(category => {
      const entries = categorizedData[id][category];

      if (entries && entries.length > 0) {
        entries.forEach(entry => {
          let parsedAnswer;
          try {
            parsedAnswer = JSON.parse(entry.final_answer.replace(/```json/g, '').replace(/```/g, ''));
          } catch (error) {
            parsedAnswer = { OpenAI_Answer: entry.final_answer };
          }

          flatData.push({
            category: entry.category,
            question: entry.question,
            final_answer: parsedAnswer.OpenAI_Answer || entry.final_answer,
            confidence_score: parsedAnswer.confidence_score || '',
          });
        });
      }
    });

    console.log(flatData);

    const worksheet = XLSX.utils.json_to_sheet(flatData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'ChatData');

    XLSX.writeFile(workbook, 'Survey_Chat_Data.xlsx');
  };

  const handleEdit = (item) => {
    setEditMode(item.uniqueId); // Set the current item as being edited
    setEditedAnswer(parseFinalAnswer(item.final_answer).answer); // Set the answer in the input field
  };

  

  const handleSave = (updatedItem) => {
    setCategorizedData(prevData => {
      // Clone previous data to avoid mutating state directly
      const updatedData = { ...prevData };
      const electricalCategories = [
        "Utility_Meter",
        "Electrical_Panel_1",
        "Electrical_Panel_2",
        "ServiceDisconnect",
        "Electrical_Labels"
      ];
  
      const subCategory = electricalCategories.includes(updatedItem.category) ? "Electrical" : updatedItem.category;
      // Define main category and subcategory for easier access
      const mainCategory = id;
      // const subCategory = category;
      
      // Check if the main category and subcategory exist
      if (!updatedData[mainCategory] || !updatedData[mainCategory][subCategory]) {
        console.warn(`Category "${subCategory}" does not exist.`);
        return prevData; // Return previous data if category is missing
      }
  
      // Locate the item index within the specified subcategory array
      const itemIndex = updatedData[mainCategory][subCategory].findIndex(
        item => item.uniqueId === updatedItem.uniqueId
      );
  
      // Update item only if it's found in the data structure
      if (itemIndex !== -1) {
        // Construct the updated answer as a JSON string for consistency
        const updatedAnswer = JSON.stringify({
          OpenAI_Answer: editedAnswer,
          confidence_score: updatedItem.confidenceScore || "N/A",
        });
  
        // Update the item within the categorized data
        updatedData[mainCategory][subCategory][itemIndex] = {
          ...updatedData[mainCategory][subCategory][itemIndex],
          final_answer: updatedAnswer,
        };
  
        console.log("Updated categorized data:", updatedData);
      } else {
        console.warn(`Item with uniqueId "${updatedItem.uniqueId}" not found.`);
      }
  
      return updatedData; // Return the updated categorized data
    });
  
    // Exit edit mode after saving
    setEditMode(null);
  };
  
  
  useEffect(() => {
    searchResponseRef.current = searchstabsResponse;
    chatDataRef.current = chatData;
    categorizedDataRef.current = categorizedData[id];
  }, [searchstabsResponse, chatData, categorizedData]);


  useEffect(() => {
    
    if (askQuestionsOpen[id]) {
      const storedUser = JSON.parse(localStorage.getItem('user'));
      const userId = storedUser?._id;
      dispatch(processSurveyChat({ userId,req_id:id }));
    }

  }, [askQuestionsOpen]);

  


  const handleToggle = (item) => {
    if (selectedQuestion === item) {
      setSelectedQuestion(null);
      setCarouselImages([]);
    } else {
      setSelectedQuestion(item);
      setCarouselImages(item.image_urls || []);
    }
  };

  // Helper function to parse final_answer
  const parseFinalAnswer = (finalAnswerStr) => {
    try {
      const cleanedStr = finalAnswerStr
        .replace(/```json/, '')
        .replace(/```/, '')
        .trim();
      const parsed = JSON.parse(cleanedStr);
      return {
        answer: parsed.OpenAI_Answer || 'No answer provided.',
        confidence: parsed.confidence_score || 'N/A',
      };
    } catch (error) {
      return {
        answer: 'Invalid answer format.',
        confidence: 'N/A',
      };
    }
  };


  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
        margin: '0 auto',
        padding: '0px !importent',
        maxWidth: '1200px',
        width: '100%',
        [theme.breakpoints.down('md')]: {
          flexDirection: 'column',
        },
      }}
    >

      {/* Loader Display */}
      {loading[id] ? (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
          <CircularProgress size={60} sx={{ color: '#3f51b5', marginBottom: '20px' }} />
          <Typography variant="h6" color="textPrimary" sx={{ marginBottom: '20px' }}>{statusMessage[id]}</Typography>
          <Box sx={{ width: '80%', marginTop: '10px' }}>
            <LinearProgress variant="determinate" value={progress} sx={{ height: 10, borderRadius: 5, '& .MuiLinearProgress-bar': { backgroundColor: '#3f51b5' } }} />
          </Box>
        </Box>
      ) : (
        <>

          {/* Survey Information Card */}
          <Card
            sx={{
              width: { xs: '100%', md: isChatScreenOpen ? '55%' : '90%' },
              height: 'auto',
              overflowY: 'auto',
              mr: { md: 2 },
              mb: { xs: 4, md: 0 },
              maxHeight: '80vh',
              position: 'relative',
            }}
          >
            {!allDataLoaded[id] && (
              <IconButton
                sx={{
                  position: 'absolute',
                  top: 16,
                  right: 16,
                  backgroundColor: '#666464',
                  '&:hover': {
                    backgroundColor: '#f1f1f1',
                  },
                }}
                onClick={downloadChatDataAsExcel}
                aria-label="Download chat data as Excel"
              >
                <DownloadIcon />
              </IconButton>
            )}

            {allDataLoaded[id] && (
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>

                <Typography variant="h6" align="center" sx={{ marginBottom: '20px' }}>
                  {statusMessage[id]}
                </Typography>

                <Stepper activeStep={activeStep[id]} alternativeLabel>
                  {steps.map((label, index) => (
                    <Step key={label}>
                      <StepLabel
                        optional={
                          index === activeStep[id] && loading[id] ? (
                            <CircularProgress size={20} sx={{ marginLeft: '10px' }} />
                          ) : null
                        }
                      >
                        {label}
                      </StepLabel>
                    </Step>
                  ))}
                </Stepper>

              </Box>
            )}
            <CardContent>
              {(!allDataLoaded[id] && allDataLoaded[id] !== undefined ) && (
                <>

                  <Typography variant="h5" align="center" fontWeight="bold" mb={4}>
                    Survey Information
                  </Typography>
                </>
              )}

              {(categorizedData && categorizedData[id] !==undefined && categorizedData[id] !== null) && Object.keys(categorizedData[id]).map(category => (
                categorizedData[id][category].length > 0 && (
                  <Box key={category} mb={4}>
                    <Typography
                      variant="h6"
                      fontWeight="bold"
                      sx={{
                        color: theme.palette.mode === 'dark' ? 'white' : 'black', // Adjusts color based on mode
                        textAlign: 'left',
                        mb: 2
                      }}
                    // sx={{ color: categoryColors[category], textAlign: 'left', mb: 2 }}
                    >
                      {category}
                    </Typography>
                    {categorizedData[id][category].map((item) => {
                      const isSelected = selectedQuestion === item;
                      const { answer, confidence } = parseFinalAnswer(item.final_answer);
                      return (
                        <Box key={item.uniqueId} mb={2} sx={{ borderBottom: '1px solid #e0e0e0', pb: 2 }}>
                          <Grid container spacing={2} alignItems="flex-start">
                            {/* Question and Button */}
                            <Grid item xs={12}>
                              <Box display="flex" alignItems="center" justifyContent="space-between">
                                <Typography variant="body1" fontWeight="bold" textAlign="left">
                                  {`Q: ${item.question}`}
                                </Typography>
                                <Box>
                                  {editMode === item.uniqueId ? (
                                    <IconButton onClick={() => handleSave(item)} aria-label="Save answer">
                                      <SaveIcon />
                                    </IconButton>
                                  ) : (
                                    <IconButton onClick={() => handleEdit(item)} aria-label="Edit answer">
                                      <EditIcon />
                                    </IconButton>
                                  )}
                                  <IconButton
                                    onClick={() => handleToggle(item)}
                                    aria-label={isSelected ? 'Collapse answer' : 'Expand answer'}
                                    sx={{
                                      transition: 'transform 0.2s',
                                      '&:hover': {
                                        transform: 'scale(1.1)',
                                      },
                                    }}
                                  >
                                    {isSelected ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                  </IconButton>
                                </Box>
                              </Box>
                              <Collapse in={!isSelected}>
                                <Box display="flex" alignItems="center" mt={1}>
                                  {editMode === item.uniqueId ? (
                                    <TextField
                                      variant="outlined"
                                      value={editedAnswer}
                                      onChange={(e) => setEditedAnswer(e.target.value)}
                                      fullWidth
                                      multiline
                                      rows={3}
                                      placeholder="Edit your answer here"
                                    />
                                  ) : (
                                    <Typography variant="body2" textAlign="left">
                                      {`A: ${answer}`}
                                    </Typography>
                                  )}

                                </Box>
                              </Collapse>
                              {/* Final Answer - Conditional Rendering */}
                              <Collapse in={isSelected}>
                                <Box display="flex" alignItems="center" mt={1}>
                                  {isSelected && item.image_urls && item.image_urls.length > 0 && (

                                    <ImageCarousel images={item.image_urls} />

                                  )}
                                </Box>
                              </Collapse>
                            </Grid>
                          </Grid>
                        </Box>
                      );
                    })}
                  </Box>
                )
              ))}

              {/* Ask Questions Section */}


              {allDataLoaded[id] && (
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '30vh',
                    width: '100%',
                  }}
                >
                  {/* Skeleton Loader */}
                  {Array.from(new Array(2)).map((_, index) => (
                    <React.Fragment key={index}>
                      <Skeleton variant="text" width="80%" height={40} />
                      <Skeleton variant="text" width="60%" height={30} />
                      <Skeleton variant="rectangular" height={200} sx={{ marginTop: '16px' }} />
                      <Skeleton variant="text" width="90%" height={30} sx={{ marginTop: '16px' }} />
                    </React.Fragment>
                  ))}
                </Box>
              )}
            </CardContent>


          </Card>

          {/* Image Carousel or User Q&A Display */}


          {isChatScreenOpen && (
            <Box
              sx={{
                width: { xs: '100%', md: '35%' },
                height: 'auto',
                // border: '1px solid gray',
                // padding:"0px !importent",
                borderRadius: '8px',
                overflow: 'hidden',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'center',
                backgroundColor: '#f9f9f9',
                position: 'relative',
                maxHeight: '80vh',
                [theme.breakpoints.down('md')]: {
                  height: '300px',
                  mb: 4,
                },
              }}
            >

              <Box sx={{ width: '100%' }}>
                {chatResponseData && <ServeyChatScreen setChatData={setChatData} req_id={id} chatResposeData={chatResponseData} />}
              </Box>

            </Box>
          )}




        </>
      )}
    </Box>
  );
};

export default SurveyDataDisplay;
