import React, { useState } from 'react';
import {
  Modal,
  Box,
  Typography,
  TextField,
  Button,
  Fade,
  Backdrop,
  IconButton,
  CircularProgress,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const style = {
  position: 'absolute',
  top: '50%', // Center vertically
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
};

const ForgotPasswordModal = ({ open, handleClose }) => {
  const [email, setEmail] = useState('');
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  // Email validation
  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  // Handle real-time validation
  const handleEmailChange = (e) => {
    const emailValue = e.target.value;
    setEmail(emailValue);

    if (!validateEmail(emailValue)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        email: 'Please enter a valid email address',
      }));
    } else {
      setErrors((prevErrors) => {
        const { email, ...rest } = prevErrors; // Remove email error if valid
        return rest;
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check email validation
    if (!validateEmail(email)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        email: 'Please enter a valid email address',
      }));
      return;
    }

    setLoading(true);

    // Simulate async call
    setTimeout(() => {
      console.log(`Forgot password for: ${email}`);
      setLoading(false);
      handleClose();
    }, 2000); // Mock API delay
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Box sx={style}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant="h6" component="h2" gutterBottom>
              Reset Password
            </Typography>
            <IconButton onClick={handleClose} sx={{ marginLeft: 'auto' }}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Box component="form" onSubmit={handleSubmit}>
            <TextField
              label="Email"
              variant="outlined"
              fullWidth
              required
              margin="normal"
              value={email}
              onChange={handleEmailChange}
              error={!!errors.email} 
              helperText={errors.email || ''} 
            />
            <Button
              type="submit"
              variant="contained"
              color={loading || !email ? 'primary' : 'black'} 
              fullWidth
              sx={{ mt: 2,
                padding:1.5,
                borderRadius:4, bgcolor: loading || !email ? 'primary.main' : 'black', color: 'white' }} // Set background and text color
              disabled={loading || !email} // Disable if loading or no email
            >
              {loading ? <CircularProgress size={24} color="inherit" /> : 'Submit'}
            </Button>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
};

export default ForgotPasswordModal;
