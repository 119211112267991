import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useNavigate } from 'react-router-dom';
import { AppProvider } from '@toolpad/core/AppProvider';
import { Toolbar, IconButton, TextField, Menu, MenuItem, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, Tooltip } from '@mui/material';
import { logoutUser } from '../redux/auth/authSlice';
import { fetchsearchesTabs, editTab, deleteTab } from '../redux/survey/uploadHistorySlice';
import { AuthContext } from '../contexts/AuthContext';
import { createTheme } from '@mui/material/styles';
import { DashboardLayout as ToolpadDashboardLayout } from '@toolpad/core/DashboardLayout';
import CommentOutlinedIcon from '@mui/icons-material/CommentOutlined';
import Upload from '@mui/icons-material/Upload';
import FolderIcon from '@mui/icons-material/Folder';
import BarChartIcon from '@mui/icons-material/BarChart';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Chip from '@mui/material/Chip';

const demoTheme = createTheme({
  cssVariables: {
    colorSchemeSelector: 'data-toolpad-color-scheme',
  },
  colorSchemes: { light: true, dark: true },
});

function CustomDrawer(props) {
  const { window } = props;
  const { user, logout } = useContext(AuthContext);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { searchstabs } = useSelector((state) => state.uploadHistory);

  const [pathname, setPathname] = useState('/new-chat');
  const [isEditing, setIsEditing] = useState(false);
  const [isEditingTabId, setIsEditingTabId] = useState(null);
  const [editedTabName, setEditedTabName] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [menuTabId, setMenuTabId] = useState(null);

  useEffect(() => {
    if (user?._id) {
      dispatch(fetchsearchesTabs(user._id));
    }
  }, [dispatch, user?._id]);



  useEffect(() => {
    localStorage.setItem('currentPath', pathname);
    if (isEditing) {
      setIsEditing(false);
      setIsEditingTabId(null);
      setEditedTabName('');
    }
  }, [pathname]);

  const handleNavigation = (path) => {
    setPathname(path);
    navigate(path);
  };

  const handleEditClick = (tabId, fileName) => {
    setIsEditing(true);
    setIsEditingTabId(tabId);
    setEditedTabName(fileName);
    handleMenuClose();
  };

  const handleMenuClick = (event, tab) => {
    setAnchorEl(event.currentTarget);
    setMenuTabId(tab._id);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setMenuTabId(null);
  };

  const handleDeleteClick = (item) => {
    setDeleteDialogOpen(true);
    setItemToDelete(item);
    handleMenuClose();
  };

  const handleDelete = () => {
    dispatch(deleteTab(itemToDelete)).then(() => {
      dispatch(fetchsearchesTabs(user?._id)); // Update list after delete
    });
    setDeleteDialogOpen(false);
  };

  const handleSave = () => {
    if (isEditingTabId && editedTabName.trim()) {
      dispatch(editTab({ searchTabId: isEditingTabId, fileName: editedTabName })).then(() => {
        dispatch(fetchsearchesTabs(user?._id)); // Update list after edit
      });
      setIsEditing(false);
      setIsEditingTabId(null);
      setEditedTabName('');
    }
  };

  const navigation = [
    {
      segment: 'new-chat',
      title: 'New Chat',
      icon: <CommentOutlinedIcon />,
      onClick: () => handleNavigation('/new-chat'),
    },
    {
      segment: 'survey-analyzer',
      title: 'Survey Analyzer',
      icon: <Upload />,
      onClick: () => handleNavigation('/survey-analyzer'),
    },
    {
      segment: 'threads',
      title: 'Threads',
      icon: <FolderIcon />,
      action: <Chip label={searchstabs.length} color="primary" size="small" />,
      children: searchstabs.map((tab) => ({
        segment: `${tab._id}`,
        title: (
          <>
            {isEditing && isEditingTabId === tab._id ? (
              <TextField
                value={editedTabName}
                onChange={(e) => setEditedTabName(e.target.value)}
                size="small"
                variant="outlined"
                onKeyDown={(e) => {
                  if (e.key === 'Enter') handleSave(); // Save on Enter key press
                }}
                autoFocus
              />
            ) : (
              <span>{tab.fileName}</span>
            )}
          </>
        ),
        icon: <BarChartIcon />,
        onClick: () => handleNavigation(`/${tab._id}`),
        action: (
          <>
            <IconButton onClick={(event) => handleMenuClick(event, tab)}>
              <MoreVertIcon />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl) && menuTabId === tab._id}
              onClose={handleMenuClose}
            >
              <MenuItem onClick={() => handleEditClick(tab._id, tab.fileName)}>
                <EditIcon sx={{ marginRight: 2 }} /> {/* Adds space between icon and text */}
                Rename
              </MenuItem>
              <MenuItem onClick={() => handleDeleteClick(tab._id)}>
                <DeleteIcon sx={{ color: 'red', marginRight: 2 }} /> {/* Changes delete icon color to red and adds space */}
                <span style={{ color: 'red' }}>Delete</span> {/* Changes delete text color to red */}
              </MenuItem>
            </Menu>

          </>
        ),
      })),
    },
    {
      segment: 'automations-template',
      title: 'Template',
      icon: <Upload />,
      onclick: () => handleNavigation('/automations-template')
    }
  ];

  return (
    <AppProvider
      branding={{ title: 'FRAMESENSE' }}
      navigation={navigation}
      session={{ user }}
      router={{
        pathname,
        searchParams: new URLSearchParams(),
        navigate: (path) => handleNavigation(path),
      }}
      theme={demoTheme}
      window={window}
      authentication={{
        signOut: () => {
          logout();
          dispatch(logoutUser());
          navigate('/');
        },
      }}
    >
      <ToolpadDashboardLayout>
        <Toolbar />
        <Outlet />
      </ToolpadDashboardLayout>
      <Dialog open={deleteDialogOpen} onClose={() => setDeleteDialogOpen(false)}>
        <DialogTitle>Delete chat?</DialogTitle>
        <DialogContent>
          <DialogContentText>Are you sure you want to delete this Survey Data?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleDelete} color="secondary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </AppProvider>
  );
}

CustomDrawer.propTypes = {
  window: PropTypes.func,
};

export default CustomDrawer;
