import React, { useEffect, useState } from 'react';
import { Box, Typography, Button } from '@mui/material';
import Chat from './ChatScreen';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { processNewChatPdf, setSelectedNewchatFile, reset } from '../redux/newchat/nechatSlice';
import SkeletonLoader from '../components/SkeletonLoader';
import Upload from '@mui/icons-material/Upload';
import { useTheme } from '@mui/material/styles';

const NewChat = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const { selectedNewChatFile, responseNewChatData, loading, error } = useSelector((state) => state.newchat);
  const [file, setFile] = useState(null);

  const handleFileChange = (event) => {
    const storedUser = JSON.parse(localStorage.getItem('user'));
    const file = event.target.files[0];
    if (file) {
      if (file.type === 'application/pdf' || file.type === 'application/zip') {
        setFile(file);
        dispatch(setSelectedNewchatFile(file)); // Update Redux state when a file is selected

        // Call the processPdf action immediately after file selection
        const userId = storedUser?._id?.slice(-8);  // Replace with actual user ID
        dispatch(processNewChatPdf({ file: file, userId })); // Automatically upload the file
      } else {
        alert('Please select a PDF or ZIP file.');
        event.target.value = '';
      }
    }
  };

  // Reset state on component unmount
  useEffect(() => {
    return () => {
      dispatch(reset());
    };
  }, [dispatch]);

  return (
    <Box sx={{  position: 'relative',textAlign: 'center' }}>
      <div>
      <Typography
        variant="h6"
        sx={{
          position: 'absolute',
          top: "-150px",
          left: "2px", // Adjust this value to position text correctly
          fontWeight: 'bold',
          color: (theme) => theme.palette.mode === 'dark' ? '#FFFFFF' : '#1F2937',
        }}
      >
        New chat
      </Typography>

      </div>
      {!loading && !responseNewChatData && (
        <Box
          sx={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: "100px"
          }}
        >
          <Box
            sx={{
              width: 96,
              height: 96,
              backgroundColor: theme.palette.mode === 'dark' ? '#616161' : '#E0E0E0',
              borderRadius: '50%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginBottom: 2,
              marginX: 'auto',
            }}
          >
            <Upload sx={{ fontSize: '48px', color: theme.palette.mode === 'dark' ? '#B0BEC5' : '#9E9E9E' }} />
          </Box>
          <Typography variant="h5" sx={{ fontWeight: 'bold', marginBottom: 1, color: theme.palette.mode === 'dark' ? '#FFFFFF' : '#1F2937' }}>
            {selectedNewChatFile ? 'Ready to analyze' : 'No content to display'}
          </Typography>
          <Typography variant="body2" sx={{ color: theme.palette.mode === 'dark' ? '#B0BEC5' : '#6B7280', marginBottom: 2 }}>
            Upload a file to start chating with your data
          </Typography>
          {selectedNewChatFile && (
            <Typography variant="body2" sx={{ color: theme.palette.mode === 'dark' ? '#B0BEC5' : '#6B7280', marginBottom: 2 }}>
              Selected file: {selectedNewChatFile.name}
            </Typography>
          )}
          {error && (
            <Typography variant="body2" color="error" sx={{ marginBottom: 2 }}>
              {error?.error?.message}
            </Typography>
          )}
          <Box sx={{ mt: 3 }}>
            <input
              type="file"
              accept="application/pdf,application/zip"  // Updated to allow PDF and ZIP files
              onChange={handleFileChange}
              style={{ display: 'none' }}
              id="file-upload"
            />
            <label htmlFor="file-upload">
              <Button
                variant="contained"
                component="span"
                sx={{
                  padding: "8px",
                  borderRadius: '5px',
                  backgroundColor: theme.palette.mode === 'dark' ? '#1976D2' : '#3B82F6',
                  color: '#FFFFFF',
                  '&:hover': {
                    backgroundColor: theme.palette.mode === 'dark' ? '#2196F3' : '#2563EB',
                  },
                }}
              >
                Choose File
              </Button>
            </label>
          </Box>
        </Box>
      )}
      {loading && <SkeletonLoader />}

      {responseNewChatData && (
        <Chat wsdata={responseNewChatData} />
      )}
    </Box>
  );
};

export default NewChat;
