import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

// Initial state
const initialState = {
    uploadHistory: [],
    searchstabs:[],
    searchstabsResponse:{},
    loading: false,
    error: null,
};

// Async thunk for calling the getUploadHistory API
export const fetchUploadHistory = createAsyncThunk(
    'uploadHistory/fetchUploadHistory',
    async (searchTabId, { rejectWithValue }) => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_API_BASE_URL}/history/getUploadHistory`,
                { params: { searchTabId } }
            );
            return response.data;
        } catch (error) {
            return rejectWithValue(error?.response?.data || 'Failed to fetch upload history');
        }
    }
);
export const fetchsearchesTabs = createAsyncThunk(
    'searchesTabs/fetchsearchesTabs',
    async (userId, { rejectWithValue }) => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_API_BASE_URL}/history/byusers-search-tabs`,
                { params: { userId } }
            );
            return response.data;
        } catch (error) {
            return rejectWithValue(error?.response?.data || 'Failed to fetch Searches Tabs');
        }
    }
);
// Async thunk for saving uploaded output
export const saveUploadedOutput = createAsyncThunk(
    'uploadHistory/saveUploadedOutput',
    async ({ searchTabId, outputs, chatOutputs }, { rejectWithValue }) => {
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_BASE_URL}/history/saveUploadedOutput`,
                {
                    searchTabId,
                    outputs,
                    chatOutputs,
                }
            );
            return response.data; // Assuming the API returns the saved data
        } catch (error) {
            return rejectWithValue(error?.response?.data || 'Failed to save uploaded output');
        }
    }
);

// Async thunk for saving search tabs
export const saveSearchTabs = createAsyncThunk(
    'uploadHistory/saveSearchTabs',
    async (searchTabsData, { rejectWithValue }) => {
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_BASE_URL}/history/saveSearchsTabs`,
                searchTabsData
            );
            return response.data; // Assuming the API returns the saved data
        } catch (error) {
            return rejectWithValue(error?.response?.data || 'Failed to save search tabs');
        }
    }
);

// Thunk to handle editing an existing tab
export const editTab = createAsyncThunk(
    'tabs/editTab',
    async (updatedData, { rejectWithValue }) => {
      try {
        const response = await axios.put(`${process.env.REACT_APP_API_BASE_URL}/history/edit-tabs`, updatedData);
        return response.data; // return the updated tab data from the API
      } catch (error) {
        return rejectWithValue(error.response.data);
      }
    }
  );

  // Thunk to handle deleting a tab
export const deleteTab = createAsyncThunk(
    'tabs/deleteTab',
    async (searchTabId, { rejectWithValue }) => {
      try {
        const response = await axios.delete(`${process.env.REACT_APP_API_BASE_URL}/history/delete-tabs/${searchTabId}`);
        return response.data; // return the result from the API
      } catch (error) {
        return rejectWithValue(error?.response?.data);
      }
    }
  );
  

// Slice
const uploadHistorySlice = createSlice({
    name: 'uploadHistory',
    initialState,
    reducers: {
        resetUploadHistory: (state) => {
            state.uploadHistory = [];
            state.loading = false;
            state.error = null;
        },
        // resetUploadHistory: (state) => {
        //     state.uploadHistory = [];
        //     state.loading = false;
        //     state.error = null;
        // }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchUploadHistory.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchUploadHistory.fulfilled, (state, action) => {
                state.loading = false;
                state.uploadHistory = action.payload;
            })
            .addCase(fetchUploadHistory.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload || 'Something went wrong';
            })
            .addCase(saveUploadedOutput.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(saveUploadedOutput.fulfilled, (state, action) => {
               
                state.loading = false;
                // state.uploadHistory.push(action.payload); // Assuming action.payload is the new output
            })
            .addCase(saveUploadedOutput.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload || 'Failed to save uploaded output';
            })
            .addCase(saveSearchTabs.pending, (state) => {
                state.loading = true;
                state.error = null;
            }).addCase(saveSearchTabs.fulfilled, (state, action) => {
                
                state.loading = false;
                state.searchstabs.push(action.payload?.data);
                state.searchstabsResponse=action.payload?.data;
                // Optionally, handle the state update based on what the API returns
            }).addCase(saveSearchTabs.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload || 'Failed to save search tabs';
            }).addCase(fetchsearchesTabs.pending, (state) => {
                state.loading = true;
                state.error = null;
            }).addCase(fetchsearchesTabs.fulfilled, (state, action) => {
                state.loading = false;
                state.searchstabs=action.payload?.data;
                // Optionally, handle the state update based on what the API returns
            }).addCase(fetchsearchesTabs.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload || 'Failed to save search tabs';
            })
            
            .addCase(editTab.pending, (state) => {
                state.loading = true;
                state.error = null;
            }).addCase(editTab.fulfilled, (state, action) => {
                state.loading = false;
                // state.searchstabs=action.payload?.data;
                // Optionally, handle the state update based on what the API returns
            }).addCase(editTab.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload || 'Failed to save search tabs';
            })
            
            .addCase(deleteTab.pending, (state) => {
                state.loading = true;
                state.error = null;
            }).addCase(deleteTab.fulfilled, (state, action) => {
                state.loading = false;
                // state.searchstabs=action.payload?.data;
                // Optionally, handle the state update based on what the API returns
            }).addCase(deleteTab.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload || 'Failed to save search tabs';
            });
    },
});

// Export the async thunks and the reducer
export const { resetUploadHistory } = uploadHistorySlice.actions;
export default uploadHistorySlice.reducer;
