import React, { useState } from 'react';
import { Modal, Box, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const ImageModal = ({ open, onClose, images, initialIndex }) => {
    const [currentIndex, setCurrentIndex] = useState(initialIndex);

    const handleNext = () => {
        if (currentIndex < images.length - 1) {
            setCurrentIndex(currentIndex + 1);
        }
    };

    const handlePrevious = () => {
        if (currentIndex > 0) {
            setCurrentIndex(currentIndex - 1);
        }
    };

    return (
        <Modal open={open} onClose={onClose}>
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '80%',
                    height: '80%',
                    backgroundColor: 'background.paper',
                    borderRadius: 2,
                    boxShadow: 24,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <IconButton onClick={onClose} sx={{ position: 'absolute', top: 10, right: 10 }}>
                    <CloseIcon />
                </IconButton>

                <IconButton onClick={handlePrevious} disabled={currentIndex === 0} sx={{ position: 'absolute', left: '10%' }}>
                    <ArrowBackIcon />
                </IconButton>

                <img
                    src={images[currentIndex]}
                    alt={`Image ${currentIndex + 1}`}
                    onError={(e) => { e.target.src = '/path-to-fallback-image.png'; }} // Fallback image
                    style={{ maxWidth: '100%', maxHeight: '100%', borderRadius: '8px' }}
                />

                <IconButton onClick={handleNext} disabled={currentIndex === images.length - 1} sx={{ position: 'absolute', right: '10%' }}>
                    <ArrowForwardIcon />
                </IconButton>
            </Box>
        </Modal>
    );
};

export default ImageModal;
