import React, { useContext } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { AuthContext } from '../contexts/AuthContext';
const PublicRoutes = () => {
  // const { user } = useSelector((state) => state.auth);
  const { user } = useContext(AuthContext);

  return !user ? <Outlet /> : <Navigate to="/new-chat" replace />;
};

export default PublicRoutes;
